import _ from "lodash";
import queryString from "query-string";
import { AppStateType } from "../../../context/AppContextTypes";
import { Resource } from "../../../resources/Resource";
import { ResourceColumn } from "../../../resources/ResourceColumn";
import ResourceInitData from "../../../resources/ResourceInitData";
import { fetchResources } from "../../../resources/ResourcesService";
import { ResourceRequest } from "../../../resources/ResourceTypes";
import {
    DataEditorItemsType,
    DataEditorMappingType,
    DataEditorResource,
    DataEditorResources,
    DataEditorState,
    ItemsByResourceRefKey,
    ItemsByResourceStoreKey,
    RefKeyToStoreKey,
    ResourceItemsType,
    ResourcesByStoreKey,
    StoreKeyToRefKey,
} from "../types/DataEditorTypes";
import DataEditorDisplayUtils from "./DataEditorDisplayUtils";

function getSelectedResourceFromLocationSearch(
    resources: typeof Resource[],
    location: any
): typeof Resource {
    const queryStringValues = queryString.parse(location.search);

    if (!queryStringValues.resource) {
        return resources[0];
    }

    const matchingResources = resources.filter(
        (resource: typeof Resource) =>
            resource.getModel().config.initDataKey === queryStringValues.resource
    );
    if (matchingResources.length === 1) {
        return matchingResources[0];
    }

    return resources[0];
}

function getMappingDefaults(resources: typeof Resource[]): DataEditorMappingType {
    const mappingStoreKeyToRefKey: StoreKeyToRefKey = resources.reduce(
        (acc: StoreKeyToRefKey, resource: typeof Resource) => {
            acc[resource.getModel().getResourceStoreKey()] = resource
                .getModel()
                .getResourceRefKey();
            return acc;
        },
        {}
    );

    const mappingRefKeyToStoreKey: RefKeyToStoreKey = resources.reduce(
        (acc: RefKeyToStoreKey, resource: typeof Resource) => {
            acc[resource.getModel().getResourceRefKey()] = resource
                .getModel()
                .getResourceStoreKey();
            return acc;
        },
        {}
    );

    return {
        storeKeyToRefKey: mappingStoreKeyToRefKey,
        refKeyToStoreKey: mappingRefKeyToStoreKey,
    };
}

function getNextId(ids: (number | null)[]): number | null {
    if (ids.length === 0) return 0;
    if (_.includes(ids, null)) return 0;

    const currentIds = ids
        .map((id: number | null) => (id === null ? 0 : +id))
        .sort((idA: number, idB: number) => idA - idB);

    const currentlyMaxId = currentIds.length === 0 ? -1 : currentIds[currentIds.length - 1];

    return currentlyMaxId + 1;
}

function getColumnStyles(columns: ResourceColumn[]): { gridTemplateColumns: string } {
    const gridTemplateColumns = columns.reduce((acc, column) => {
        const frWidth = column.width || 1;
        const pxWidth = 20 * frWidth;
        acc += `minmax(${pxWidth}px, ${frWidth}fr) `;
        return acc;
    }, "");

    return { gridTemplateColumns };
}

function containsOrderColumn(columns: ResourceColumn[]): boolean {
    return columns.some(({ field }) => field === "order");
}

function getSelectedResourceItemsCount(dataEditorState: DataEditorState): number {
    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();

    return dataEditorState.getItemsByStoreKey(storeKey).length;
}

function getSelectedResourcePageSize(dataEditorState: DataEditorState): number {
    if (dataEditorState.getSelectedResource()) {
        return dataEditorState.getSelectedResource().getModel().config.pageSize;
    }
    return 0;
}

function getPageSize(resource: typeof Resource) {
    return resource.getModel().config.pageSize;
}

function setPageIndex(resource: typeof Resource, pageIndex: number): any {
    resource.getModel().config.pageIndex = pageIndex;

    return resource;
}

function getPageIndex(resource: typeof Resource): number {
    return resource.getModel().config.pageIndex || 0;
}

function getPagesCount(itemsCount: number, pageSize: number): number {
    return Math.ceil(itemsCount / pageSize);
}

function getPageSlice(items: Resource[], resource: typeof Resource): Resource[] {
    const pageIndex = getPageIndex(resource) || 0;
    const pageSize = getPageSize(resource) || 200;
    const pagesCount = getPagesCount(items.length, pageSize);

    if (pagesCount < 2) {
        return items;
    }

    return items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
}

function indexItemsByResourceStoreKey(resources: typeof Resource[]): ItemsByResourceStoreKey {
    return resources.reduce((acc: ItemsByResourceStoreKey, resource: typeof Resource) => {
        acc[resource.getModel().getResourceStoreKey()] = [];
        return acc;
    }, {});
}

function indexResourcesByStoreKey(resources: typeof Resource[]): ResourcesByStoreKey {
    return resources.reduce((acc: ResourcesByStoreKey, resource: typeof Resource) => {
        acc[resource.getModel().getResourceStoreKey()] = resource;
        return acc;
    }, {});
}

function extractColumnNames(resource: typeof Resource | null) {
    if (!resource) {
        return [];
    }

    return resource
        .getModel()
        .getColumns()
        .map(({ field }: { field: string }) => field);
}

function findColumnName(resource: typeof Resource | null, columnName: string) {
    if (!resource) {
        return false;
    }

    return resource
        .getModel()
        .getColumns()
        .find(({ field }) => field === columnName);
}

function hasColumn(resource: typeof Resource, columnName: string) {
    return findColumnName(resource, columnName);
}

function buildNewItemFromColumnNames(columnNames: string[], columnValues: string[]) {
    return columnNames.reduce((acc, name, i) => {
        if (columnValues[i] !== undefined) {
            acc[name] = columnValues[i];
            // transform booleans to Int
            if (typeof acc[name] === "boolean") {
                acc[name] = +acc[name];
            }
        }
        return acc;
    }, {} as { [key: string]: any });
}

async function createNewItem(
    resource: typeof Resource,
    items: Resource[],
    columnNames: string[],
    columnValues: any[]
): Promise<Resource> {
    const newId = hasColumn(resource, "id") ? getNextId(items.map((item) => item.data.id)) : null;
    const newOrderIndex = hasColumn(resource, "order") ? items.length : null;
    const newItem = buildNewItemFromColumnNames(columnNames, columnValues);
    const createdItem = await resource.getModel().create(newId, newItem, true, newOrderIndex);

    return new resource(createdItem);
}

function getItemsByResourceStoreKey(resourcesItems: ResourceItemsType[]): ItemsByResourceStoreKey {
    const itemsByResourceStoreKey = resourcesItems.reduce(
        (acc: ItemsByResourceStoreKey, resourceItems: ResourceItemsType) => {
            acc[resourceItems.resource.getModel().getResourceStoreKey()] = resourceItems.items;
            return acc;
        },
        {}
    );

    return itemsByResourceStoreKey;
}

function getItemsByResourceRefKey(resourcesItems: ResourceItemsType[]): ItemsByResourceRefKey {
    const itemsByResourceRefKeyAndId = resourcesItems.reduce(
        (acc: ItemsByResourceRefKey, resourceItems: ResourceItemsType) => {
            acc[resourceItems.resource.getModel().getResourceRefKey()] = resourceItems.items;
            return acc;
        },
        {}
    );

    return itemsByResourceRefKeyAndId;
}

async function getResourcesItems(
    appState: AppStateType,
    resources: typeof Resource[]
): Promise<ResourceItemsType[]> {
    const resourceRequests = resources.map((resource: typeof Resource): ResourceRequest => {
        const filter = resource.getModel().getFilter(appState);
        return [resource, filter];
    });

    const resourceResponses = await fetchResources(resourceRequests);

    const resourcesItems = resourceResponses.map(
        (resourceResponse: Resource[], index: number): ResourceItemsType => {
            return { resource: resources[index], items: resourceResponse };
        }
    );

    return resourcesItems;
}

function clearItemsByResource(
    items: DataEditorItemsType,
    resource: typeof Resource
): DataEditorItemsType {
    const storeKey = resource.getModel().getResourceStoreKey();
    const resourceRefKey = resource.getModel().getResourceRefKey();

    items.byResourceStoreKey[storeKey] = [];
    items.byResourceRefKey[resourceRefKey] = [];

    return items;
}

function clearItemByResource(
    items: DataEditorItemsType,
    resource: typeof Resource,
    resourceItem: Resource
): DataEditorItemsType {
    // get keys
    const resourceStoreKey = resource.getModel().getResourceStoreKey();
    const resourceRefKey = resource.getModel().getResourceRefKey();

    // cannot find resources
    if (!items.byResourceStoreKey[resourceStoreKey]) {
        throw Error("Invalid resource!");
    }
    if (!items.byResourceRefKey[resourceRefKey]) {
        throw Error("Invalid ref resource!");
    }

    // get item sources
    const itemsByResourceStoreKey = items.byResourceStoreKey[resourceStoreKey];
    const itemsByResourceRefKey = items.byResourceRefKey[resourceRefKey];

    // get item index
    const resourceStoreKeyIndex = itemsByResourceStoreKey.findIndex((item: Resource) =>
        item.equals(resourceItem)
    );
    const resourceRefKeyIndex = itemsByResourceRefKey.findIndex((item: Resource) =>
        item.equals(resourceItem)
    );

    // cannot be found
    if (resourceStoreKeyIndex === -1) {
        throw Error("Invalid deletion attempt!");
    }
    if (resourceRefKeyIndex === -1) {
        throw Error("Invalid deletion attempt!");
    }

    // delete from all locations
    itemsByResourceStoreKey.splice(resourceStoreKeyIndex, 1);
    itemsByResourceRefKey.splice(resourceRefKeyIndex, 1);

    return items;
}

function addItemByResource(
    items: DataEditorItemsType,
    resource: typeof Resource,
    resourceItem: Resource
): DataEditorItemsType {
    const storeKey = resource.getModel().getResourceStoreKey();
    items.byResourceStoreKey[storeKey]?.push(resourceItem);

    const resourceRefKey = resource.getModel().getResourceRefKey();
    items.byResourceRefKey[resourceRefKey]?.push(resourceItem);

    return items;
}

function getAddMenuInitialValues(dataEditorState: DataEditorState) {
    return dataEditorState.getSelectedResource().getModel().editor.getNewItemTemplate();
}

// put first the key columns, then the required ones
function sortAddMenuColumns(a: ResourceColumn, b: ResourceColumn) {
    if (b.isKey && !a.isKey) {
        return 1;
    } else if (a.isKey && !b.isKey) {
        return -1;
    }

    if (b.isRequired && !a.isRequired) {
        return 1;
    } else if (a.isRequired && !b.isRequired) {
        return -1;
    }

    return 0;
}

function prepareDisplayColumnsForAddMenu(dataEditorState: DataEditorState) {
    return dataEditorState
        .getSelectedResource()
        .getModel()
        .config.columns.filter(({ hide }) => !hide)
        .sort(sortAddMenuColumns);
}

function replaceItem(items: DataEditorItemsType, item: Resource): DataEditorItemsType {
    // find and replace in byResourceStoreKey
    const resourceStoreKey = item.getModel().getResourceStoreKey();
    const resourceRefKey = item.getModel().getResourceRefKey();

    for (let i = 0; i < items.byResourceStoreKey[resourceStoreKey].length; i++) {
        if (items.byResourceStoreKey[resourceStoreKey][i].equals(item)) {
            items.byResourceStoreKey[resourceStoreKey][i] = item;
        }
    }

    // find and replace in byResourceRefKeyAndId
    for (let i = 0; i < items.byResourceRefKey[resourceRefKey].length; i++) {
        if (items.byResourceRefKey[resourceRefKey][i].equals(item)) {
            items.byResourceRefKey[resourceRefKey][i] = item;
        }
    }

    return items;
}

async function fetchItemsFromBackup(resource: typeof Resource) {
    return await ResourceInitData(resource.getModel().config.initDataKey);
}

async function loadDataEditorItems(
    appState: AppStateType,
    resources: typeof Resource[]
): Promise<DataEditorItemsType> {
    const resourceItems: ResourceItemsType[] = await DataEditorUtils.getResourcesItems(
        appState,
        resources
    );

    const items: DataEditorItemsType = {
        byResourceStoreKey: DataEditorUtils.getItemsByResourceStoreKey(resourceItems),
        byResourceRefKey: DataEditorUtils.getItemsByResourceRefKey(resourceItems),
    };

    return items;
}

function getSelectedResourceItems(dataEditorState: DataEditorState): Resource[] {
    const storeKey = dataEditorState.getSelectedResource().getModel().getResourceStoreKey();
    return dataEditorState.getItemsByStoreKey(storeKey) || [];
}

function getItemsDefaults(resources: typeof Resource[]): DataEditorItemsType {
    const itemsByResourceStoreKey: ItemsByResourceStoreKey = resources.reduce(
        (acc: ItemsByResourceStoreKey, resource: typeof Resource) => {
            acc[resource.getModel().getResourceStoreKey()] = [];
            return acc;
        },
        {}
    );

    const itemsByResourceRefKey: ItemsByResourceRefKey = resources.reduce(
        (acc: ItemsByResourceRefKey, resource: typeof Resource) => {
            acc[resource.getModel().getResourceRefKey()] = [];
            return acc;
        },
        {}
    );

    return {
        byResourceStoreKey: itemsByResourceStoreKey,
        byResourceRefKey: itemsByResourceRefKey,
    };
}

function getResourcesInit(
    dataEditorResources: DataEditorResource[],
    resources: typeof Resource[],
    location: any
): DataEditorResources {
    const selectedResourceFromLocationSearch =
        DataEditorUtils.getSelectedResourceFromLocationSearch(resources, location);
    const resourcesByStoreKey = DataEditorUtils.indexResourcesByStoreKey(resources);

    return {
        all: resources,
        allDataEditorResources: dataEditorResources,
        selected: selectedResourceFromLocationSearch || resources[0],
        byStoreKey: resourcesByStoreKey,
    };
}

function searchResourceItem(resourceItem: Resource, searchText: string): boolean {
    return JSON.stringify(resourceItem.getDisplay())
        .toLowerCase()
        .includes(searchText.toLowerCase());
}

// get the items of the selected resource filtered by its search text
function searchSelectedResourceItems(dataEditorState: DataEditorState): Resource[] {
    const resource = dataEditorState.getSelectedResource();
    const storeKey = resource.getModel().getResourceStoreKey();
    const searchText = dataEditorState.display.searchText[storeKey];
    const resourceItems = dataEditorState.getItemsByStoreKey(storeKey);

    if (!searchText) {
        return resourceItems;
    }

    return resourceItems.filter((resourceItem) => searchResourceItem(resourceItem, searchText));
}

const DataEditorUtils = {
    loadDataEditorItems,
    getSelectedResourceFromLocationSearch,
    getNextId,
    getColumnStyles,
    containsOrderColumn,
    getSelectedResourceItemsCount,
    getSelectedResourcePageSize,
    setPageIndex,
    getPageIndex,
    getPagesCount,
    getPageSlice,
    indexItemsByResourceStoreKey,
    indexResourcesByStoreKey,
    extractColumnNames,
    findColumnName,
    createNewItem,
    getItemsByResourceStoreKey,
    getItemsByResourceRefKey,
    getResourcesItems,
    clearItemsByResource,
    clearItemByResource,
    addItemByResource,
    getAddMenuInitialValues,
    prepareDisplayColumnsForAddMenu,
    replaceItem,
    fetchItemsFromBackup,
    getSelectedResourceItems,
    getItemsDefaults,
    getMappingDefaults,
    getResourcesInit,
    searchSelectedResourceItems,
};

export default DataEditorUtils;
